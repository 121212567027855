.hero {
  position: relative;
  width: 100%;
  background-image: url(./images/hero.jpg);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.heroOverlay {
  background: linear-gradient(145deg, #0d45d6, #1fc296);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.96;
  z-index: 1;
}

.heroContent {
  position: relative;
  z-index: 2;
}

.legalContent {
  width: 80%;
  padding: 20px;
  margin: auto;
}

.legalHeading {
  font-size: 18px;
  font-weight: bold;
}

.legalText {
  font-size: 15px;
}

.legalLink {
  color: #262b4f;
}

.legalLink:hover {
  font-weight: bold;
}

.footerLinks {
  list-style: none;
  margin: 0;
  padding: 0;
}
